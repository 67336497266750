import './Footer.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const copyRightStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.2)'
}

function Footer() {
    return (
        <div className="Footer mt-auto">
            <footer className="text-center text-white">
                <div className="container pt-4">
                    <section className="mb-4">
                        <a className="btn btn-link btn-floating btn-lg text-dark m-1"
                           href="https://www.instagram.com/educopoils31"
                           role="button" data-mdb-ripple-color="dark" target="_blank" rel="noreferrer"><i
                            className="bi bi-instagram"></i></a>
                        <a className="btn btn-link btn-floating btn-lg text-dark m-1"
                           href="https://www.facebook.com/profile.php?id=100082154108499"
                           role="button" data-mdb-ripple-color="dark" target="_blank" rel="noreferrer"><i className="bi bi-facebook"></i></a>
                        <a className="btn btn-link btn-floating btn-lg text-dark m-1"
                           href="mailto:cg.educopoils@gmail.com"
                           role="button" data-mdb-ripple-color="dark"><i className="bi bi-envelope"></i></a>
                        <a className="btn btn-link btn-floating btn-lg text-dark m-1" href="tel:+33788661355"
                           role="button" data-mdb-ripple-color="dark"><i className="bi bi-phone"></i></a>
                    </section>
                </div>
                <div className="text-center text-dark p-3" style={copyRightStyle}>
                    © 2022 <a className="text-dark" href="https://www.augui-dev.fr/">Augustin IAMUNDO</a>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
