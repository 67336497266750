import './App.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from "./Header";
import Home from "./Home";
import Footer from "./Footer";
import NotFound from "./NotFound";

function App() {
    return (
        <div className="App d-flex flex-column min-vh-100">
            <Header/>
            <div className="main">
                <Router>
                    <Routes>
                        <Route path={"/"} element={<Home/>}/>
                        <Route path={"*"} element={<NotFound/>}/>
                    </Routes>
                </Router>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
