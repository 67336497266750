function Home() {
    return (
        <div className="Home">
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col">
                        <h1>Bienvenue chez Educ O'poils 🐶</h1>
                        <div className="container-fluid text-center">
                            <div className="row">
                                <h1>
                                    Notre site est en maintenance pour le moment, 
                                    mais vous pouvez nous contacter par les liens en bas de page en cas de besoin.
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;