import './Header.css';

const headerStyle = {
    margin: 'auto'
}

function Header() {
    return (
        <div className="Header">
            <nav className="navbar navbar-expand-lg sticky-top navbar-dark bg-dark" id="header">
                <div className="container-fluid">
                    <a href="https://www.educopoils31.fr/">
                        <img id="logo" src="/img/logo.png" alt="logo"
                             className="img-fluid float-start" height="100" width="100"/>
                    </a>
                    <div id="headerTitle" style={headerStyle}>
                        <a href="https://www.educopoils31.fr/" className="nav-item"><h1>Educ O'poils</h1></a>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Header;
